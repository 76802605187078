import { externalLinks } from './external-links';
import {TrackingCategories, TrackingCategoryType, TrackingNames, TrackingNameType} from '@/models/trackingModel';
import { getJoinTodayLink } from '@/resources/utils';
import { ICON_TYPES } from '@/components/Icons';

export interface IFooterLinkProps {
  title: string;
  trackingType: TrackingCategoryType;
  links: IFooterLinkBlock[];
}

export interface IFooterSocialProps {
  title: string,
  link: string,
  icon: ICON_TYPES,
  trackingName: TrackingNameType,
  trackingCategory: TrackingCategoryType
}

export interface IFooterLinkBlock {
  external?: string;
  link?: string;
  title: string;
  trackingName: TrackingNameType
}
export const FOOTER_LINKS: IFooterLinkProps[] = [
  {
    title: 'worker',
    trackingType: TrackingCategories.Footer,
    links: [
      {
        external: externalLinks.sjWorkerFaq,
        title: 'faqs',
        trackingName: TrackingNames.FooterWorkerFaqClick
      },
      {
        external: externalLinks.webCenterLink,
        title: 'webCenter',
        trackingName: TrackingNames.WebCenterClick
      },
      {
        external: externalLinks.benefitsUrl,
        title: 'benefits',
        trackingName: TrackingNames.FooterBenefitsClick
      },
      {
        external: externalLinks.transparencyInCoverage,
        title: 'transparencyInCoverage',
        trackingName: TrackingNames.TransparencyCoverageClick
      }
    ]
  },
  {
    title: 'business',
    trackingType: TrackingCategories.Footer,
    links: [
      {
        external: getJoinTodayLink(),
        title: 'joinToday',
        trackingName: TrackingNames.FooterBusinessJoinClick
      },
      {
        external: '/clients',
        title: 'csClientsTitle',
        trackingName: TrackingNames.FooterClients
      },
      {
        external: externalLinks.sjBusinessFaq,
        title: 'faqs',
        trackingName: TrackingNames.FooterBusinessFaqClick
      },
      {
        external: '/clients/gsa-schedule',
        title: 'gsaSchedule',
        trackingName: TrackingNames.GsaScheduleClick
      },
    ]
  },
  {
    title: 'aboutUs',
    trackingType: TrackingCategories.Footer,
    links: [
      {
        link: '/contact#top',
        title: 'contactUs',
        trackingName: TrackingNames.FooterAboutContactUsClick
      },
      {
        external: externalLinks.sjPrivacy,
        title: 'privacyPolicy',
        trackingName: TrackingNames.FooterAboutPrivacyPolicyClick
      },
      {
        external: '/about-us/employment',
        title: 'CSEmploymentVerify',
        trackingName: TrackingNames.FooterAboutPrivacyPolicyClick
      },
      {
        external: '/about-us/corporate-social-responsibility',
        title: 'CScorporateSocial',
        trackingName: TrackingNames.FooterAboutPrivacyPolicyClick
      },

    ]
  }
];
