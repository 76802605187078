import React from 'react';
import './styles.scss';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import RenderParagraphs from '@/components/RenderParagraphs';

const EmploymentVerify = () => {
    const { translate } = useTranslationHook();

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol title={translate('csEmploymentTitle')}
                             spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <RenderParagraphs translateLabel='csEmploymentParagraphs'/>
                             }
                             rightContent={
                                 <div className='job-seeker-image'>
                                     <ImageCol src={'core-staff-employment.png'}
                                               alt={'standing-workers'}/>
                                 </div>
                             }
            />
            <br/>

        </div>
    );
};

export default EmploymentVerify;