import * as React from 'react';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { ImageCol } from '../ImageCol';
import './styles.scss';

interface ITileProps {
    title: string;
    content: string;
    image: string;
}

export const Tile: React.FC<ITileProps> = ({ content, image, title }) => {

    const { translate } = useTranslationHook();

    return (
        <div className='tile'>
            <div className='tile__content'>
                {title}
            </div>
            {image && <ImageCol src={image} alt={image}/>}
        </div>);
};
