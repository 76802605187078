import React from 'react';
import './styles.scss';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { externalLinks } from '@/models/external-links';
import RenderDotPoints from '@/components/RenderDotPoints';
import RenderParagraphs from '@/components/RenderParagraphs';

const GSASchedule = () => {
    const { translate } = useTranslationHook();
    return (
        <div className='mt-4' id='top'>
            <ImageContentCol reverse
                             title={translate('gsaSchedule')}
                             spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                     <ImageCol src={'core-staff-gsa.png'}
                                               alt={'standing-workers'}/>
                             }
                             rightContent={
                                 <>
                                     <h4>
                                         {translate('csGSATitle')}
                                     </h4>
                                     <p>
                                         {translate('csGSAParagraph')}
                                     </p>
                                     <RenderDotPoints translateLabel='csGSAItemList'/>
                                     <RenderParagraphs translateLabel='csGSAContactItemList'/>
                                     <a href={externalLinks.gsaSchedule}>
                                         {translate('csGSAClickableLink')}
                                     </a>
                                 </>
                             }
            />

        </div>
    );
};

export default GSASchedule;