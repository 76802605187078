import * as React from 'react';
import './styles.scss';
import { ContentCol } from '@/components/ContentCol';
import { ImageCol } from '@/components/ImageCol';
import {
    IMAGE_CONTENT_COL_SPACING,
    ImageContentCol
} from '@/components/ImageContentCol';
import { PartnerBanner } from '@/components/PartnerBanner';
import * as HOME from '@/data/pages/partner-premier';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { TrackingCategories } from '@/models/trackingModel';
import RenderParagraphs from '@/components/RenderParagraphs';

const CoreStaff = () => {
    const {
        translate,
        translateObject
    } = useTranslationHook();

    const listContentLeft: any = translateObject('coreStaffHomeListPointsLeft');
    const listContentRight: any = translateObject('coreStaffHomeListPointsRight');
    return (
        <>
            <div className='home-page'>
                <PartnerBanner {...HOME.PARTNER_BANNER}
                               img={{
                                   src: '/images/corestaff.png',
                                   alt: 'corestaff'
                               }}/>
                <ImageContentCol leftContent={
                                    <ContentCol title={[{ text: translate('coreStaffDotPointsTitleLeft') }]}
                                                listContent={listContentLeft}
                                                trackingCategory={TrackingCategories.Premier}/>
                                 }
                                 rightContent={
                                     <ContentCol title={[{ text: translate('coreStaffDotPointsTitleRight') }]}
                                                 listContent={listContentRight}
                                                 trackingCategory={TrackingCategories.Premier}/>
                                 }/>
                <div className='home-paragraph'>
                    <p>
                        {translate('csHomeDescription')}
                    </p>


                </div>
                <ImageContentCol spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                                 leftContent={
                                     <ImageCol src={'core-staff-home-bottom.png'}
                                               alt={'laptop-animate'}/>

                                 }
                                 rightContent={
                                     <>
                                         <br/>
                                         <h3>{translate('coreStaffHomeTitle')}</h3>
                                         <RenderParagraphs translateLabel='csHomeParagraphs'/>
                                         <i>{translate('csHomeItalicPhrase')}</i>
                                     </>
                                 }/>
                {/*<PartnerFooter/>*/}
            </div>
        </>
    );
};
export default CoreStaff;
