import React from 'react';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import RenderDotPoints from '@/components/RenderDotPoints';
import RenderParagraphs from '@/components/RenderParagraphs';
import { useTranslationHook } from '@/hooks/useTranslationHook';

const HumanResourcesSection = () => {
    const { translate } = useTranslationHook();

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol title={translate('hrSectionTitle')}
                             spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <>
                                     <RenderParagraphs translateLabel='hrParagraphs'/>
                                     <h5>{translate('hrSubTitle')}</h5>
                                     <RenderDotPoints translateLabel='hrItemList'/>
                                 </>
                             }
                             rightContent={
                                 <div className='job-seeker-image'>
                                     <ImageCol src={'core-staff-hr.png'}
                                               alt={'standing-workers'}/>
                                 </div>
                             }
            />

        </div>
    );
};

export default HumanResourcesSection;