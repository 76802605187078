import React from 'react';
import './styles.scss';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { ContentCol } from '@/components/ContentCol';
import { TrackingCategories } from '@/models/trackingModel';
import RenderParagraphs from '@/components/RenderParagraphs';

const Clients = () => {
    const {
        translate,
        translateObject
    } = useTranslationHook();

    const navigate = [
        '/find-a-job/our-specialties/accounting-and-finance',
        '/find-a-job/our-specialties/administrative-and-clerical',
        '/find-a-job/our-specialties/call-center-customer-care',
        '/find-a-job/our-specialties/human-resources-hr',
        '/find-a-job/our-specialties/information-records-management',
        '/find-a-job/our-specialties/light-industrial-assembly'
    ];

    const csClientsItemListLeft: any = translateObject('csClientsItemListLeft');
    const csClientsItemListRight: any = translateObject('csClientsItemListRight');
    const csClientsItemListBottom: any = translateObject('csClientsItemListBottom');

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol
                title={translate('csClientsTitle')}
                leftContent={
                    <RenderParagraphs translateLabel='csClientsParagraphs'/>
                }
                rightContent={
                    <div>
                        <div className='job-seeker-image'>
                            <ImageCol src={'core-staff-events.png'} alt={'standing-workers'}/>
                        </div>
                    </div>
                }
            />
            <ImageContentCol
                leftContent={
                    <ContentCol title={[{ text: 'clientRightTitle' }]}
                                listContent={csClientsItemListLeft}
                                trackingCategory={TrackingCategories.Premier}/>
                }
                rightContent={
                    <div>
                        <ContentCol title={[{ text: 'clientLeftTitle' }]}
                                    listContent={csClientsItemListRight}
                                    trackingCategory={TrackingCategories.Premier}
                                    navigate={navigate}/>
                    </div>
                }
            />
            <ImageContentCol reverse
                             spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <>
                                     <ContentCol title={[{ text: 'csClientsPortfolioTitle' }]}
                                                 listContent={csClientsItemListBottom}
                                                 trackingCategory={TrackingCategories.Premier}/>
                                     <p>
                                         {translate('csClientsNewWayToHelpParagraph')}
                                     </p>
                                 </>
                             }
                             rightContent={
                                 <div>
                                     <div className='job-seeker-image'>
                                         <ImageCol src={'core-staff-administrative.png'}
                                                   alt={'standing-workers'}/>
                                     </div>
                                     <p>
                                         {translate('csClientsResponsabilityParagraph')}
                                     </p>
                                 </div>
                             }
            />
        </div>
    );
};

export default Clients;