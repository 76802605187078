import React from 'react';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { Link } from 'react-router-dom';
import RenderParagraphs from '@/components/RenderParagraphs';
import RenderDotPoints from '@/components/RenderDotPoints';
import './styles.scss';

const CSJobSeekers = () => {
    const {
        translate
    } = useTranslationHook();

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol title={translate('jobsSeekersTitle')}
                             spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <>
                                     <h4>{translate('jobSeekersSectorTitle')}</h4>
                                     <RenderParagraphs translateLabel='jobSeekersParagraphs'/>
                                     <RenderDotPoints translateLabel='jobSeekersList'/>
                                 </>
                             }
                             rightContent={
                                 <ImageCol src={'core-staff-jobs-seeker.png'}
                                           alt={'standing-workers'}/>
                             }/>
            <ImageContentCol spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <ImageCol src={'core-staff-contact.png'}
                                           alt={'standing-workers'}/>
                             }
                             rightContent={
                                 <>
                                     <h3>{translate('carrersJobTitle')}</h3>
                                     <p>{translate('careersJobGuidesDescription')}</p>
                                     <ul>
                                         <Link to='/blog-page/01'>
                                             <li>{translate('carrersJobListResume')}</li>
                                         </Link>
                                         <Link to='/blog-page/02'>
                                             <li>{translate('carrersJobListInterview')}</li>
                                         </Link>
                                     </ul>
                                     <p>{translate('careersJobGetInTouch')}</p>
                                 </>
                             }/>
        </div>
    );
};

export default CSJobSeekers;
