import { TrackingNames, TrackingNameType} from '@/models/trackingModel';

export interface INavLink {
  title: string;
  link: string;
  trackingName: TrackingNameType
} 

const NAV_LINKS: INavLink[] = [
  {
    title: 'findAJob',
    link: '/find-a-job/our-specialties',
    trackingName: TrackingNames.NavPhilosophyClick
  },
  {
    title: 'jobSeekers',
    link: '/jobs-seekers#top',
    trackingName: TrackingNames.NavBlogClick
  },
   {
    title: 'jobsLabel',
    link: '/jobs#top',
    trackingName: TrackingNames.NavJobsClick
  },
  {
    title: 'ourTechnology',
    link: '/our-technology#top',
    trackingName: TrackingNames.NavTechnologyClick
  },
  {
    title: 'contact',
    link: '/contact#top',
    trackingName: TrackingNames.NavContactClick
  },
]

export default NAV_LINKS