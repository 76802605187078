import FirstClassStaffing from '@/companies/1stClassStaffing';
import BrandPack from '@/companies/BrandPack';
import EZPayroll from '@/companies/EZPayroll';
import CoreStaff from '@/companies/CoreStaff';
import CSCorporateSocial from '@/companies/CoreStaff/pages/CorporateSocial';
import CSAccounting from '@/companies/CoreStaff/pages/FindAJob/AccountingSection';
import CSAdministrative from '@/companies/CoreStaff/pages/FindAJob/AdministrativeSection';
import CSCallCenter from '@/companies/CoreStaff/pages/FindAJob/CallCenterSection';
import CSHumanResources from '@/companies/CoreStaff/pages/FindAJob/HumanResourcesSection';
import CSInformation from '@/companies/CoreStaff/pages/FindAJob/InformationManagementSection';
import CSIndustrial from '@/companies/CoreStaff/pages/FindAJob/LightIndustrialSection';
import CSEvents from '@/companies/CoreStaff/pages/Events';
import CSJobSeeker from '@/companies/CoreStaff/pages/JobSeekers';
import CSOurSpecialties from '@/companies/CoreStaff/pages/FindAJob/OurSpecialties';
import BlogPage from '@/companies/CoreStaff/pages/JobSeekers/BlogPage';
import Jobs from '@/companies/Premier/pages/Jobs/Jobs';
import OurTechnology from '@/companies/CoreStaff/pages/OurTechnology';
import EmploymentVerify from '@/companies/CoreStaff/pages/EmploymentVerify';
import Philosophy from '@/companies/Premier/pages/Philosophy';
import Contact from '@/companies/Premier/pages/Contact';
import About from '@/pages/About';
import Clients from '@/companies/CoreStaff/pages/Clients';
import GSASchedule from '@/companies/CoreStaff/pages/GSASchedule';



export const pages = [
  {
    routeName: '/',
    Component: CoreStaff,
  },
  {
    routeName: '/find-a-job/our-specialties/accounting-and-finance',
    Component: CSAccounting,
  },
  {
    routeName: '/find-a-job/our-specialties/administrative-and-clerical',
    Component: CSAdministrative,
  },
  {
    routeName: '/find-a-job/our-specialties/call-center-customer-care',
    Component: CSCallCenter,
  },
  {
    routeName: '/find-a-job/our-specialties/human-resources-hr',
    Component: CSHumanResources,
  },
  {
    routeName: '/find-a-job/our-specialties/information-records-management',
    Component: CSInformation,
  },
  {
    routeName: '/find-a-job/our-specialties/light-industrial-assembly',
    Component: CSIndustrial,
  },
  {
    routeName: '/about-us/corporate-social-responsibility',
    Component: CSCorporateSocial,
  },
  {
    routeName: '/find-a-job/our-specialties',
    Component: CSOurSpecialties,
  },
  {
    routeName: '/events',
    Component: CSEvents,
  },
  {
    routeName: '/jobs-seekers',
    Component: CSJobSeeker,
  },
  {
    routeName:'/1stclassstaffing',
    Component: FirstClassStaffing
  },
  {
    routeName:'/brandpack',
    Component:BrandPack
  },
  {
    routeName:'/ezpayroll',
    Component:EZPayroll
  },
  {
    routeName:'/corestaff',
    Component:CoreStaff
  },
  {
    routeName:'/jobs',
    Component:Jobs
  },
  {
    routeName:'/our-technology',
    Component:OurTechnology
  },
  {
    routeName:'/philosophy',
    Component:Philosophy
  },
  {
    routeName:'/about-us/employment',
    Component:EmploymentVerify
  },
  {
    routeName:'/about-us',
    Component:About
  },
  {
    routeName:'/clients/gsa-schedule',
    Component:GSASchedule
  },
  {
    routeName:'/clients',
    Component:Clients
  },
  {
    routeName:'/blog-page/:blogId',
    Component:BlogPage
  },
  {
    routeName:'/contact',
    Component:Contact
  },

]