import * as React from 'react';

import './styles.scss';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { getImageUrl } from '@/utils';
import RenderParagraphs from '@/components/RenderParagraphs';

const OurTechnology = () => {
    const { translate } = useTranslationHook();

    return (
        <div id='top' className='our-technology-page'>
            <div className='animated-container'>
                <ImageContentCol title={translate('ourTechnology')}
                                 spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                                 leftContent={<RenderParagraphs translateLabel='coreStaffOurTechnologyLeftParagraphs'/>}
                                 rightContent={
                                     <div className='image-container'>
                                         <img
                                             src={getImageUrl('partner-technology.png')}
                                             className={'home-image'}
                                             alt={'partner-technology'}
                                         />
                                     </div>
                                 }
                />
            </div>
            <div className='home-paragraph'>
                <RenderParagraphs translateLabel='coreStaffOurTechnologyBottomParagraphs'/>
            </div>
        </div>
    );
};
export default OurTechnology;
