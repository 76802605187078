import React from 'react';
import './styles.scss';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';

const CorporateSocial = () => {
    const { translate } = useTranslationHook();

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol title={translate('csPageSectionTitle')}
                             spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <>
                                     <h3>{translate('csPageResponsibleTitle')}</h3>
                                     <p>{translate('csPageResponsibleDescription')}</p>
                                     <h4>{translate('csPageInclusionTitle')}</h4>
                                     <p>{translate('csPageInclusionDescription')}</p>
                                     <h4>{translate('csPageHealthTitle')}</h4>
                                     <p>{translate('csPageHealthDescription')}</p>
                                 </>
                             }
                             rightContent={
                                 <div className='job-seeker-image'>
                                     <ImageCol src={'core-staff-cs1.png'}
                                               alt={'standing-workers'}/>
                                 </div>}/>
            <ImageContentCol spacing={IMAGE_CONTENT_COL_SPACING.BOTTOM}
                             leftContent={
                                 <>
                                     <h4>{translate('csPageFacilitiesTitle')}</h4>
                                     <p>{translate('csPageFacilititesDescription')}</p>
                                     <h4>{translate('csPageCommunitiesTitle')}</h4>
                                     <p>{translate('csPageCommunititesDescription')}</p>
                                 </>
                             }
                             rightContent={
                                 <div className='job-seeker-image'>
                                     <ImageCol src={'core-staff-cs2.png'}
                                               alt={'standing-workers'}/>
                                 </div>
                             }/>
            <br/>
        </div>
    );
};

export default CorporateSocial;