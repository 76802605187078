import * as React from 'react';

import './styles.scss';
import {
  ImageContentCol,
  IMAGE_CONTENT_COL_SPACING,
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { getImageUrl } from '@/utils';
import InfoTilesViewButtons from '@/controllers/InfoTilesViewButtons';
import RenderParagraphs from '@/components/RenderParagraphs';
import RenderDotPoints from '@/components/RenderDotPoints';

const CSOurSpecialties = () => {
  const {
    translate,
    translateObject
  } = useTranslationHook();

  const listContent: any = translateObject('coreStaffItemListContent');
  const tilesList = {
    content: listContent,
    images:[
      'core-staff-accounting-2.png',
      'core-staff-administrative.png',
      'core-staff-callCenter.png',
      'core-staff-hr.png',
      'core-staff-infoMan.png',
      'core-staff-industrial.png'
    ],
    navigate:[
      '/find-a-job/our-specialties/accounting-and-finance',
      '/find-a-job/our-specialties/administrative-and-clerical',
      '/find-a-job/our-specialties/call-center-customer-care',
      '/find-a-job/our-specialties/human-resources-hr',
      '/find-a-job/our-specialties/information-records-management',
      '/find-a-job/our-specialties/light-industrial-assembly'
    ]
  };
  return (
    <div id='top' className='our-technology-page'>
      <div className='animated-container'>
        <ImageContentCol
          title={translate('csOurSpecialtiesMainTitle')}
          spacing={IMAGE_CONTENT_COL_SPACING.NONE}
          leftContent={
            <div className='paragraph-container'>
              <RenderParagraphs translateLabel='csOurSpecialtiesParagraphsTop'/>
            </div>
          }
          rightContent={
            <div className='image-container'>
              <img
                src={getImageUrl('partner-technology.png')}
                className={'home-image'}
                alt={'partner-technology'}
              />
            </div>
          }
        />
      </div>
      <div className='home-paragraph'>
        <h3 className='main-title'>
          {translate('csOurSpecialtiesSecondaryTitle')}
        </h3>
        <h4 className='main-title'>
          {translate('csOurSpecialtiesSubTitle')}
        </h4>
        <RenderParagraphs translateLabel='csOurSpecialtiesParagraphsBottom'/>
        <ul>
          <RenderDotPoints translateLabel='csOurSpecialtiesItemList'/>
        </ul>
      </div>
      <InfoTilesViewButtons title={translate('infoBoxLabel')}
                     tileList={tilesList.content}
                     image={tilesList.images}
                     navigate={tilesList.navigate}
            />
    </div>
  );
};
export default CSOurSpecialties;
