import * as React from 'react';
import { useTranslationHook } from '@/hooks/useTranslationHook';

interface ParagraphProps {
    translateLabel: string;
    className?: string;
}

const RenderDotPoints = ({
                             translateLabel,
                             className
                         }: ParagraphProps) => {
    const { translateObject } = useTranslationHook();
    const paragraphs: any = translateObject(`${translateLabel}`);

    const render = paragraphs.map((item: string) => {
        return <li className={className}>{item}</li>;
    });

    return (
        <ul>
            {render}
        </ul>
    );
};

export default RenderDotPoints;