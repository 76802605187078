import React from 'react';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import RenderDotPoints from '@/components/RenderDotPoints';
import RenderParagraphs from '@/components/RenderParagraphs';
import { useTranslationHook } from '@/hooks/useTranslationHook';

const CallCenterSection = () => {
    const { translate } = useTranslationHook();

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol title={translate('callCenterTitle')}
                             spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                             leftContent={
                                 <>
                                     <RenderParagraphs translateLabel='callCenterParagraphs'/>
                                     <h5>{translate('callCenterSubTitle')}</h5>
                                     <RenderDotPoints translateLabel='callCenterItemList'/>
                                 </>
                             }
                             rightContent={
                                 <div className='job-seeker-image'>
                                     <ImageCol src={'core-staff-callCenter.png'}
                                               alt={'standing-workers'}/>
                                 </div>
                             }
            />

        </div>
    );
};

export default CallCenterSection;